<template>
  <div class="va-row">
    <div class="flex md12 xs12">
      <vuestic-widget :headerText="'call-campaign.calls.title' | translate">
        <div class="calls row">
          <div class="col-6">
            <router-link
              :to="{
                name: 'call-campaign-calls-list',
                params: { type: 'single' }
              }"
            >
              <div class="card">
                <img
                  :src="require('@/assets/images/call-campaign/call.png')"
                  alt=""
                />
                <h1>{{ $t('call-campaign.calls.single.title') }}</h1>
                <p>{{ $t('call-campaign.calls.single.description') }}</p>
              </div>
            </router-link>
          </div>
          <!-- <div class="col-4">
            <router-link
              :to="{
                name: 'call-campaign-calls-list',
                params: { type: 'api' }
              }"
            >
              <div class="card">
                <img
                  :src="require('@/assets/images/call-campaign/api.png')"
                  alt=""
                />
                <h1>Api</h1>
                <p>Create/Show api calls</p>
              </div>
            </router-link>
          </div> -->
          <div class="col-6">
            <router-link :to="{ name: 'call-campaign-excel-calls-list' }">
              <div class="card">
                <img
                  :src="require('@/assets/images/call-campaign/excel.png')"
                  alt=""
                />
                <h1>{{ $t('call-campaign.calls.excel.title') }}</h1>
                <p>{{ $t('call-campaign.calls.excel.description') }}</p>
              </div>
            </router-link>
          </div>
        </div>
      </vuestic-widget>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.card {
  padding: 2rem;
  img {
    width: 64px;
    margin-bottom: 12px;
  }
  h1,
  p {
    margin: 0;
  }
  h1 {
    color: #474747;
    margin: 8px 0;
  }
  p {
    color: #929292;
  }
}
</style>
